<template>
  <div>
    <div>
      <v-row class="pa-0 ma-0 mx-2">
        <v-col cols="12" md="5" v-if="selectedType == 'provider'">
          <v-card class="border-radius-lg mt-5">
            <v-row class="justify-space-between pt-3 pb-5 px-7">
              <div>
                <h5 class="text-h5 my-2 font-weight-bold text-typo">Chats</h5>
              </div>
            </v-row>
            <v-divider></v-divider>
            <v-card-text
              v-if="projects.data && projects.data.length"
              class="pa-0 ma-0"
              style="overflow: auto; max-height: 75vh"
            >
              <div>
                <v-card
                  @click="openChat(project)"
                  class="border-radius-0"
                  :class="
                    selectedProject && selectedProject.id == project.id
                      ? 'bg-gradient-warning'
                      : ''
                  "
                  v-for="project in projects.data"
                  :key="project.id"
                >
                  <div>
                    <v-row class="align-center pa-0 ma-0">
                      <v-avatar
                        size="50"
                        class="ma-3 text-uppercase text-dark"
                        color="#F3F5F6"
                      >
                        <v-img
                          :src="getImage(project.imagePath)"
                          :alt="project.serviceProvider.name"
                          v-on:error="onImgError"
                        />
                      </v-avatar>
                      <v-col v-if="project.chats.length">
                        <v-row class="ma-0 pa-0 justify-space-between">
                          <div class="my-1 font-weight-bolder text-md">
                            {{ project.title }}
                          </div>
                          <div
                            class="my-1 font-weight-normal text-secondary text-md"
                          >
                            {{
                              project.chats[project.chats.length - 1].updatedAt
                                | moment("DD.MM.YYYY HH:mm")
                            }}
                          </div>
                        </v-row>
                        <v-row class="ma-0 pa-0 justify-space-between">
                          <v-col>
                            <div class="my-1 font-weight-normal text-md">
                              {{
                                project.chats[project.chats.length - 1].message
                              }}
                            </div>
                          </v-col>
                          <v-badge
                            v-if="
                              project.chats.find(
                                (element) =>
                                  element.read != true &&
                                  element.creatorId != userInformations.id
                              )
                            "
                            color="red"
                            class="mr-5 mt-10"
                          ></v-badge>
                        </v-row>
                      </v-col>
                      <v-col v-else>
                        <v-row class="ma-0 pa-0 justify-space-between">
                          <div class="my-1 font-weight-bolder text-md">
                            {{ project.title }}
                          </div>
                        </v-row>

                        <div class="my-1 font-weight-normal text-md">
                          Chat beginnen
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-card-text>
            <div class="pa-5" v-else>
              <div>Du hast noch keine Projekte, jetzt hinzufügen?</div>

              <v-btn
                @click="openNewProjectModal()"
                :elevation="0"
                color="#cb0c9f"
                class="font-weight-bolder btn-dark bg-light py-4 px-4 mt-5"
                small
              >
                Projekt hinzufügen
              </v-btn>
            </div>
          </v-card></v-col
        >
        <v-col cols="12" md="7" v-if="selectedProject">
          <v-card class="border-radius-lg mt-5">
            <v-row class="justify-space-between pt-3 pb-5 px-7">
              <div>
                <h5 class="text-h5 my-2 font-weight-bold text-typo">
                  {{ selectedProject.title }}
                </h5>
              </div>
            </v-row>
            <v-divider></v-divider>
            <div
              style="height: 60vh; overflow-y: auto"
              fill-height
              class="align-start pa-3"
              id="chat-list"
            >
              <ChatBubble
                :sender="chat.creator.id == userInformations.id ? true : false"
                class="mb-3"
                :chat="chat"
                v-for="chat in chatMessages.data"
                :key="chat.id"
              />
            </div>
            <v-card-actions class="pa-4">
              <v-form @submit.prevent lazy-validation style="width: 80%">
                <v-text-field
                  placeholder="Neue Nachricht..."
                  class="rounded-lg mt-3 pb-3"
                  v-model="message"
                  hide-details=""
                  filled
                  rounded
                  dense
                  @keyup.native.enter="sendMessage()"
                >
                  <template v-slot:append-inner>
                    <v-icon size="25">fas fa-comment</v-icon>
                  </template>
                </v-text-field>
              </v-form>
              <v-btn
                @click="sendMessage()"
                :elevation="0"
                color="#cb0c9f"
                class="font-weight-bolder btn-dark bg-light py-4 px-4 mx-2"
              >
                Senden
              </v-btn>
            </v-card-actions></v-card
          ></v-col
        ></v-row
      >
    </div>
  </div>
</template>

<script>
import ChatBubble from "./Widgets/ChatBubble.vue";
import axiosAuth from "@/shared/configs/axios-auth";
export default {
  name: "Dashboard",
  components: { ChatBubble },
  data() {
    return {
      message: "",
      intervall: null,
      initialMessageCount: 0,
    };
  },
  mounted() {
    this.scrollToBottom();
    this.getMessages();
    this.intervall = setInterval(() => {
      this.getMessages();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervall);
  },
  methods: {
    sendMessage() {
      if (this.message) {
        this.$store.dispatch("postMessage", {
          message: this.message,
          projectId: this.selectedProject.id,
          companyId: this.selectedCompany.id,
        });
        this.message = "";
        this.scrollToBottom();
      }
    },
    getMessages() {
      let vm = this;
      if (this.selectedProject) {
        // this.$store.dispatch("getMessages", this.selectedProject.id);
        axiosAuth
          .get("/messages?projectId=" + this.selectedProject.id, {
            headers: {
              version: "v1.1",
            },
          })
          .then((res) => {
            this.$store.commit("loadingMessages", false);
            if (!res.data.message) {
              vm.$store.commit("chatMessages", res.data);
              if (vm.initialMessageCount != res.data.data.length) {
                vm.scrollToBottom();
              }
              vm.initialMessageCount = res.data.data.length;
            }
          })
          .catch((error) => {
            console.log(error);
            vm.$store.commit("loadingMessages", false);
            vm.$store.commit("companies", []);
          });

        this.$store.dispatch("setMessagesToRead", this.selectedProject.id);
        // this.scrollToBottom();
      }
      this.$store.dispatch("getProjects", this.selectedCompany.id);
    },
    scrollToBottom() {
      setTimeout(function () {
        var div = document.getElementById("chat-list");
        div.scrollTop = div.scrollHeight;
      }, 500);
    },
  },
};
</script>
