<template>
  <v-row class="pa-0 ma-0 ma-2" v-if="!sender">
    <v-avatar size="50" class="mr-3 text-uppercase text-dark" color="#F3F5F6">
      <v-img
        :src="getImage(chat.creator.avatar)"
        :alt="chat.creator.firstname"
        v-on:error="onImgError"
      />
    </v-avatar>
    <v-col class="pa-0 ma-0">
      <v-card class="bg-light">
        <div class="py-2 px-5">
          <div class="my-1 font-weight-bolder text-md">
            {{ chat.creator.firstname }}
            {{ chat.creator.lastname }}
          </div>
          <div class="my-1 font-weight-normal text-md">
            {{ chat.message }}
          </div>
          <small class="text-secondary">
            {{ chat.updatedAt | moment("DD.MM.YYYY HH:mm") }}
          </small>
        </div>
      </v-card>
    </v-col>
    <v-col cols="3"></v-col>
  </v-row>
  <v-row class="pa-0 ma-0 ma-2 justify-end" v-else>
    <v-col cols="3"></v-col>
    <v-col class="pa-0 ma-0">
      <v-card style="background-color: rgba(255, 188, 116, 0.2) !important">
        <div class="py-2 px-5">
          <div class="my-1 font-weight-bolder text-md text-warning">Du</div>
          <div class="my-1 font-weight-normal text-md">
            {{ chat.message }}
          </div>
          <small class="text-secondary">
            {{ chat.updatedAt | moment("DD.MM.YYYY HH:mm") }}
          </small>
        </div>
      </v-card>
    </v-col>

    <v-avatar size="50" class="ml-3 text-uppercase text-dark" color="#F3F5F6">
      <v-img
        :src="getImage(chat.creator.avatar)"
        :alt="chat.creator.firstname"
        v-on:error="onImgError"
      />
    </v-avatar>
  </v-row>
</template>

<script>
export default {
  props: {
    chat: null,
    sender: false,
  },
};
</script>

<style>
</style>